module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"西荻窪の整体ぺんぎん堂","short_name":"ぺんぎん堂","description":"普通の整体とちょっと違うアメリカ式整体オステオパシーの専門院。西荻窪南口より5分。","start_url":"/","background_color":"#FFFBEB","theme_color":"#495579","display":"standalone","icon":"src/images/icon.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://penguindo.net/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"トップ","usePathPrefix":"/blog","crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"コラム"},{"pathname":"/contact","crumbLabel":"ご予約・お問い合わせ"},{"pathname":"/covid19","crumbLabel":"新型コロナウィルス対策"},{"pathname":"/faq","crumbLabel":"よくあるご質問"},{"pathname":"/kasai","crumbLabel":"葛西院"},{"pathname":"/access","crumbLabel":"アクセス"},{"pathname":"/nishiogi","crumbLabel":"ご案内"},{"pathname":"/osteopathy","crumbLabel":"オステオパシーとは"},{"pathname":"/profile","crumbLabel":"プロフィール"},{"pathname":"/therapy","crumbLabel":"当院の施術"},{"pathname":"/thanks","crumbLabel":"お問い合わせありがとうございます"},{"pathname":"/404","crumbLabel":"お探しのページが見つかりません"},{"pathname":"/voices","crumbLabel":"お客様の声"},{"pathname":"/en","crumbLabel":"Home"},{"pathname":"/en/therapy","crumbLabel":"Our Therapy"},{"pathname":"/en/profile","crumbLabel":"About Us"},{"pathname":"/en/nishiogi","crumbLabel":"Infomation"},{"pathname":"/en/access","crumbLabel":"Access"},{"pathname":"/en/kasai","crumbLabel":"Kasai Info."},{"pathname":"/en/contact","crumbLabel":"Contact Us"},{"pathname":"/en/thanks","crumbLabel":"Thank You"},{"pathname":"/en/404","crumbLabel":"Not Found"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-autolink-headers","id":"4e296cab-d951-5062-8188-51216cb96826","name":"gatsby-remark-autolink-headers","version":"5.25.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"className":"inline-block ml-1","isIconAfterHeader":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"inline-block ml-1","isIconAfterHeader":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70,"duration":2000},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K6H565C","includeInDevelopment":false,"enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
